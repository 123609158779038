import React from 'react';

import { Modal } from 'reactstrap';
import classNames from 'classnames';

import './Modal.module.scss';

const MainModal = ({
  children,
  isOpen,
  toggle,
  title,
  shouldShowAsBottomSheet,
  showModalHeader,
  shouldHideCloseAction,
  className,
  contentClassName,
  placeholder,
  onPlaceholderClick,
  backdrop,
}) => {
  return (
    <Modal
      className={classNames(
        className,
        'modal__wrapper',
        shouldShowAsBottomSheet && 'bottomSheet__wrapper',
      )}
      contentClassName={contentClassName}
      isOpen={isOpen}
      toggle={toggle}
      backdrop={backdrop || true}
    >
      {
        showModalHeader && (
          <div className="modal__wrapper--header mb-2">
            <div className="header__left">
              {title && (
                <h4>{title}</h4>
              )}
            </div>
            {
              placeholder && (<span className="placeholder" onClick={onPlaceholderClick}>{placeholder}</span>)
            }
            {
              !shouldHideCloseAction && (
                <div className="header__right">
                  <span onClick={toggle}>Tutup</span>
                </div>
              )
            }
          </div>
        )
      }
      {children}
    </Modal>
  );
};

export default MainModal;